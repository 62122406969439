@import url(./fonts/Golos/Golos.css);
@import url(./vendor/normalize.css);

body {
  background: white;
  font-family: 'Golos', 'Arial', 'Helvetica', sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  background: transparent;

}


* {
  box-sizing: border-box;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
  color: black;
}

h3 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
}

@keyframes rotate90 {
  from {
    transform: rotate(0) scale(1);
  }
  to {
    transform: rotate(-90deg) scale(.6);
  }
}
@keyframes rotate90back {
  from {
    transform: rotate(-90deg) scale(.6);
  }
  to {
    transform: rotate(0) scale(1);
  }
}